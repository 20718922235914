import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from "@/utils/auth"; // get token from cookie

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/pageList", formData);
}

function topList(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/topList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/personInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/personInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/personInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/personInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/personInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/personInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/personInfo/exportXls", formData);
  //window.open(constant.serverUrl + "/base/personInfo/exportXls?token=" + getToken());
}

function enabledFace(formData) {
  //人脸授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledFace", formData);
}

function enabledFaceList(ids) {
  //批量人脸授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledFaceList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function enabledCard(formData) {
  //刷卡授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledCard", formData);
}

function enabledApp(formData) {
  //手机授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledApp", formData);
}

function enabledWechatNotice(formData) {
  //接收微信通知
  return request.post(constant.serverUrl + "/base/personInfo/enabledWechatNotice", formData);
}

function enabledGuest(formData) {
  //访客授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledGuest", formData);
}

function dataSync(idList) {
  //数据同步
  return request.post(constant.serverUrl + "/base/personInfo/dataSync", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function studentRemove(id) {
  return request.post(constant.serverUrl + "/base/personInfo/studentDelete/" + id);
}

function studentBatchRemove(idList) {
  return request.post(constant.serverUrl + "/base/personInfo/studentBatchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function enabledSync(formData) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/personInfo/enabledSync", formData);
}

function enabledSyncList(ids) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/personInfo/enabledSyncList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function clearFaceImg(id) {
  return request.post(constant.serverUrl + "/base/personInfo/clearFaceImg", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchClearFaceImg(idList) {
  return request.post(constant.serverUrl + "/base/personInfo/batchClearFaceImg", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function unbindWechat(id){
  return request.post(constant.serverUrl + "/base/personInfo/unbindWechat", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function lifeRecordList(formData){
  return request.post(constant.serverUrl + "/base/personInfo/lifeRecordList", formData);
}

function bindWechat(userId) {
  var formData = new FormData();

  formData.append("expire_seconds","86400");
  formData.append("action_name","QR_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_BIND_PERSON_WECHAT_CODE + "," + userId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create",formData);
}

function listByCompanyId(formData){
  return request.post(constant.serverUrl + "/base/personInfo/listByCompanyId", formData);
}

function queryJobTypeList(companyId){
  return request.get(constant.serverUrl + `/base/personInfo/queryJobTypeList?companyId=${companyId}`);
}

function inspectionList(formData){
  //晨午检
  return request.post(constant.serverUrl + "/base/personInfo/inspectionList", formData);
}

function exportInspection(formData){
  return request.post(constant.serverUrl + "/base/personInfo/exportInspection", formData);
}

function updateHealthyCode(personId){
  return request.get(constant.serverUrl + `/base/personInfo/updateHealthyCode?personId=${personId}`);
}

function schoolCommissionerListData(formData){
  return request.post(constant.serverUrl + "/base/personInfo/schoolCommissionerListData", formData);
}


function schoolCommissionerAllData(formData){
  return request.post(constant.serverUrl + "/base/personInfo/schoolCommissionerAllData", formData);
}

function healthStatusList(){
  return request.post(constant.serverUrl + "/base/personInfo/healthStatusList");
}



export default {
  pageList, create, edit, add, update, remove, batchRemove, exportXls,
  enabledFace, enabledCard, enabledApp, enabledGuest, dataSync, enabledFaceList,
  enabledWechatNotice, enabledSyncList, enabledSync,
  studentRemove, studentBatchRemove,
  clearFaceImg,batchClearFaceImg,unbindWechat,lifeRecordList,topList,bindWechat,
  listByCompanyId,queryJobTypeList,inspectionList,exportInspection,updateHealthyCode,schoolCommissionerListData,
  schoolCommissionerAllData,healthStatusList
}