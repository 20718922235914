<template>
    <div>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">健康打卡日报</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <div>
            <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
                <el-form-item label="查询日期" prop="date">
                        <el-date-picker
                        v-model="queryModel.date"
                        type="date"
                        size="mini" style="width:150px;"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                        </el-date-picker>
                </el-form-item>
                <el-form-item label="健康情况" prop="healthStatus">
                    <el-select v-model="queryModel.healthStatus" placeholder="请选择" size="mini">
                        <el-option
                            v-for="item in healthStatusList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校" prop="companyId">
                    <el-select-tree
                        size="mini"
                        :props="props"
                        :options="companyResult"
                        v-model="queryModel.companyId"
                        height="200"
                    ></el-select-tree>
                </el-form-item>
                <el-form-item>
                    <el-button
                    type="primary"
                    size="mini"
                    icon="ios-search"
                    @click="handleSeach"
                    :loading="loading"
                    >查询</el-button>&nbsp;
                    <el-button
                        type="info"
                        size="mini"
                        style="margin-left: 8px"
                        @click="handleReset('queryForm')"
                    >重置</el-button>
                </el-form-item>            
            </el-form>
            
        </div>
        <el-divider></el-divider>
        <div class="vonChart">
			<div class="vonChart-left">
				<h4>健康监测统计</h4>
				<ul>
					<li class="vonChart-li-2">
						<div class=" bgblue">
							<img src="../../assets/icon01.png" >
							<div>
								<p>应测人数</p>
								<h2>{{model.num}}</h2>
							</div>
						</div>	
					</li>
					<li class="vonChart-li-2">
						<div class=" bggreen">
							<img src="../../assets/icon01.png" >
							<div>
								<p>已测人数</p>
								<h2>{{model.checkedNum}}</h2>
							</div>
						</div>	
					</li>
				</ul>
				<ul>
					<li class="vonChart-li-3">
						<div class=" bgpink">
							<p>体温异常</p>
							<h2>{{model.abnormalTempNum}}</h2>	
						</div>	
					</li>
					<li class="vonChart-li-3">
						<div class=" bgorg">
							<p>出荆人数</p>
							<h2>{{model.outCityNum}}</h2>	
						</div>	
					</li>
					<li class="vonChart-li-3">
						<div class=" bgorg">
							<p>出省人数</p>
							<h2>{{model.outProvinceNum}}</h2>	
						</div>	
					</li>
				</ul>
			</div>
			<div class="vonChart-right">
				<h4>健康情况统计</h4>
				<div class="vonChart-chart" id="goodsSalesStatisticsDiv" v-loading="pieLoading" element-loading-text="正在加载中">

				</div>
			</div>
        </div>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="exportXls"
        >导出报表</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            style="min-height:550px;"
            :height="tableHeight"
            v-loading="loading"
            stripe
            >
            <el-table-column prop="name" label="学校名称" width="250"></el-table-column>
            <el-table-column prop="typeName" label="学校类型" width="200"></el-table-column>
            <el-table-column prop="num" label="应测/已测人数" width="200">
                <template slot-scope="{row}">
                    <span>{{row.num}}/{{row.checkedNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="inProvinceNum" label="市外/省外人数" width="200">
                <template slot-scope="{row}">
                    <span>{{row.inProvinceNum}}/{{row.outProvinceNum}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="feverNum" label="发热"></el-table-column>
            <el-table-column prop="coughNum" label="咳嗽" ></el-table-column>
            <el-table-column prop="runnyNoseNum" label="流涕" ></el-table-column>
            <el-table-column prop="vomitNum" label="呕吐" ></el-table-column>
            <el-table-column prop="diarrheaNum" label="腹泻" ></el-table-column>
            </el-table>
            <el-pagination
            :current-page.sync="pageIndex"
            :total="totalElements"
            :page-sizes="pageSizeList"
            @current-change="changePage"
            @size-change="pageSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
    </div>
</template>
<script>
import personInfoApi from "@/api/base/personInfo";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";
export default {
    name:"baseSchoolCommissionerList",
    data() {
        return {
            queryModel:{
                date:"",  //日期,
                companyId:"", 
                healthStatus:""
            },
            model:{
                num:"",
                checkedNum:"",
                abnormalTempNum:"",
                outCityNum:"",
                outProvinceNum:"",
            },
            loading: false,
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0,
            field: "",
            direction: "",
            pageSizeList: [10, 20, 30],
            multipleSelection: [],
            downloadLoading: false,
            tableHeight: 550,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
            companyResult: [],
            pieData:[],
            pieLoading:false,
            loadingText: '加载中',
            healthStatusList:[],
        }
    },
    methods:{
        everyDayMeasuringView() {
            let myChart = this.$echarts.init(
                document.getElementById("goodsSalesStatisticsDiv")
            );

                  // 绘制图表
            myChart.setOption({
                title: {
                    textAlign: 'center'
                },
                 tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [{
                    name: '健康情况',
                    type: 'pie',
                    radius: '80%',
                    center: ['50%', '60%'],
                    data: this.pieData,
                    animation: false,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            });
        },
        changePage(pageIndex,exportFlag) {
            var self = this;

            self.loading = true;

            self.pageIndex = pageIndex;
            var formData = new FormData();

            if(exportFlag!=null){
                if(exportFlag){
                    formData.append("pageSize", 10000);
                }
                else{
                    formData.append("pageSize", self.pageSize);
                }
                formData.append("exportFlag", exportFlag);
            }
            else{
                formData.append("pageSize", self.pageSize);
                formData.append("exportFlag", false);
            }
            formData.append("pageIndex", self.pageIndex);
            formData.append("date", self.queryModel.date);

            if(self.queryModel.companyId!=null&&self.queryModel.companyId!=""){
                formData.append("companyId", self.queryModel.companyId);
            }
            else{
                formData.append("companyId", "");
            }
            
            formData.append("healthStatus", self.queryModel.healthStatus);


            personInfoApi
                .schoolCommissionerListData(formData)
                .then(function(response) {
                    self.loading = false;

                    var jsonData = response.data.data;

                    if(exportFlag){
                        //导出
                        self.$message({
                            showClose: true,
                            type: "success",
                            message: `报表已生成，<a href="${jsonData}">请点击链接下载</a>`,
                            dangerouslyUseHTMLString: true,
                            duration: 30000
                        });
                    }
                    else{
                        //分页查看
                        self.tableData = jsonData.data;
                        self.totalPages = jsonData.totalPages;
                        self.totalElements = jsonData.recordsTotal;
                    }

                })
                .catch(error => {
                self.loading = false;
                // self.$message.error(error + "");
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.$nextTick(() => {
                this.changePage(this.pageIndex,false);
            });
        },
        loadTree() {
            var formData = new FormData();

            companyInfoApi.loadChildren(formData).then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                this.treeData = jsonData.data;
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },
        loadChildren(tree, treeNode, resolve) {
            var formData = new FormData();
            formData.append("parentId", tree.id);

            companyInfoApi.loadChildren(formData).then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                resolve(jsonData.data);
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },
        handleSeach(){
            this.changePage(1,false);
            this.commissionerAllData();
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        commissionerAllData(){
            var self = this;

            this.pieLoading = true;

            var formData = new FormData();

            formData.append("date", self.queryModel.date);

            personInfoApi.schoolCommissionerAllData(formData).then(function(response) {

                var jsonData = response.data.data;

                self.model.num = jsonData.num;
                self.model.checkedNum = jsonData.checkedNum;
                self.model.abnormalTempNum = jsonData.abnormalTempNum;
                self.model.outCityNum = jsonData.outCityNum;
                self.model.outProvinceNum = jsonData.outProvinceNum;

                self.pieData = jsonData.list;
                self.everyDayMeasuringView();

                self.pieLoading=false;

            })
            .catch(error => {
                self.loading = false;
                self.pieLoading=false;
                // self.$message.error(error + "");
            });
        },
        exportXls() {
            this.changePage(1,true);
        }

    },
    created() {
        var self = this;

        companyInfoApi.treeList().then(function(response) {
            var jsonData = response.data;
            if (jsonData.result) {
                if (jsonData.data != null && jsonData.data != "") {
                    self.companyResult = jsonData.data;
                }
            }
        });

        personInfoApi.healthStatusList().then(function(response) {
            var jsonData = response.data;
            if (jsonData.result) {
                if (jsonData.data != null && jsonData.data != "") {
                    self.healthStatusList = jsonData.data;
                }
            }
        });

        this.loadTree();
    },
    async mounted() {
        this.changePage(1,false);
        this.commissionerAllData();
    },
    components: {
        "el-select-tree": SelectTree,
    }
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

li{
    list-style: none;
}
.vonChart{
    display: flex;
}
.vonChart-left{
    margin-right:15px
}
.vonChart-left,
.vonChart-right{
    background:white;
    padding:15px 30px;
    width:50%
}
.vonChart-left ul{
    display: flex;
    margin:0 -10px
}
.vonChart-left img{
    margin-right:10px;
    width:50px
}
.vonChart-left p,
.vonChart-left h2{
    margin:0;
    color:white
}
.bgblue{
    background:#648CFF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding:30px
}
.bggreen{
    background:#389E0D;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding:30px
}
.bgpink{
    background:#FF6666;
    border-radius: 3px;
    padding:30px
}
.bgorg{
    background:#FF6902;
    border-radius: 3px;
    padding:30px
}
.vonChart-li-2{
    width:50%;
    padding:10px
}
.vonChart-li-3{
    width:33.33333%;
    padding:10px;
    text-align: left;
}
.vonChart-chart{
    width:100%;
    height:240px;
    overflow: hidden;
    padding:10px 0
}
</style>